<template>
  <dv-border-box-13 class="left-top">
    <div class="bottom-charts">
      <div class="bc-chart-item">
        <div class="title">设备状态</div>
        <dv-active-ring-chart :config="deviceStatusL" />
        <Label-Tag :config="labelConfig" />
      </div>

      <div class="bc-chart-item1">
        <div v-for="item in deviceStatusR">
          <span
            class="item"
            :class="
              item.name == '火警设备'
                ? 'huojing'
                : item.name == '故障设备'
                ? 'guzhang'
                : item.name == '失联设备'
                ? 'shilian'
                : item.name == '其他设备'
                ? 'other'
                : 'normal'
            "
          ></span>
          <span>{{ item.name }}</span>
          <span class="number">{{ item.number }} 个</span>
        </div>
      </div>
    </div>
  </dv-border-box-13>
</template>

<script>
// 初始化结构
import LabelTag from "../LabelTag";

export default {
  name: "BottomCharts",
  components: {
    LabelTag,
  },
  data() {
    return {
      // 设备状态 个数
      deviceStatusR: [
        {
          name: "火警设备",
          number: "5",
        },
        {
          name: "故障设备",
          number: "5",
        },
        {
          name: "失联设备",
          number: "5",
        },
        {
          name: "其他设备",
          number: "5",
        },
      ],

      // ***************
      deviceStatusL: {
        data: [
          {
            name: "火警",
            value: 356,
          },
          {
            name: "故障",
            value: 215,
          },
          {
            name: "失联",
            value: 90,
          },
          {
            name: "其他",
            value: 317,
          },
        ],
        color: ["#00baff", "#3de7c9", "#fff", "#ffc530"],
        radius: "60%",
        activeRadius: "70%",
      },
      labelConfig: {
        data: ["火警", "故障", "失联", "其他"],
      },
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="less">
.bottom-charts .label-tag {
  height: 0 !important;
}
.left-top {
  width: 25vw !important;
  height: 30vh !important;
}
.bottom-charts {
  width: 25vw;
  height: 30vh;
  display: flex;
  position: relative;
  .bc-chart-item {
    width: 50%;
    box-sizing: border-box;
    .title {
      box-sizing: border-box;
      padding: 12% 0 0% 8%;
      font-size: 18px;
      color: aqua;
    }
  }
  .bc-chart-item1 {
    width: 200px;
    margin-left: 35px;

    padding: 30px 0;

    div {
      line-height: 60px;
      font-size: 17px;
      .number {
        float: right;
        padding-right: 20px;
      }
    }
  }

  .dv-active-ring-chart {
    height: calc(~"100% - 80px");
  }
  .label-tag {
    height: 30px;
  }
  .active-ring-name {
    font-size: 18px !important;
  }
  .decoration-1,
  .decoration-2,
  .decoration-3 {
    display: absolute;
    left: 0%;
  }
}
.item {
  margin-right: 8px;
  display: inline-block;
  width: 10px;
  height: 10px;
}

.huojing {
  background-color: #00baff;
  color: #00baff;
}
.guzhang {
  background-color: #3de7c9;
  color: #3de7c9;
}
.shilian {
  background-color: #fff;
  color: #fff;
}
.other {
  background-color: #ffc530;
  color: #ffc530;
}
.normal {
  background-color: rgb(80, 74, 74);
}
</style>