<template>
  <div id="map">
    <baidu-map
      class="map"
      :zoom="zoom"
      center="津南区"
      :mapStyle="mapStyle"
      :scroll-wheel-zoom="true"
    >
      <bml-marker-clusterer :averageCenter="true">

        <bm-marker
          @click="markerClicked(marker)"
          v-for="marker of markersOfDevice" 
          animation="BMAP_ANIMATION_BOUNCE"
          :position="{ lng: marker.lng, lat: marker.lat }"
        ></bm-marker>


        <!-- :icon="{url:baseUrl+marker.iconurl,size: {width: 50, height: 50}}" -->


      </bml-marker-clusterer>
      <bm-control>
        <el-select
          v-model="deviceTypeChecked"
          placeholder="请选择设备类型"
          class="serachinput"
          collapse-tags
          multiple
          @change="checkedValue($event)"
        >
          <el-option
            v-for="item in deviceType"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </bm-control>
    </baidu-map>
    <el-dialog title="设备信息" :visible.sync="dialogVisible" width="35%">
      <div></div>
      <div style="height: 25vh; overflow: scroll">
        <div class="container-box">
          <span class="item-box1">ID：</span>
          <span class="item-box2">{{ staticAttributes.id }}</span>
        </div>
        <div class="container-box">
          <span class="item-box1">设备类型：</span>
          <span class="item-box2">{{ staticAttributes.typeName }}</span>
        </div>

        <div v-for="(item, index) in dynamicProperties" class="container-box">
          <span class="item-box1">{{ item.id }}：</span>
          <span class="item-box2">{{ item.value==''?'正常':item.value }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import BaseData from '@/assets/config/BaseData';


import deviceTypeAboutMap from "@/api/manageApi/DeviceTypeAboutMap.js";
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";

import { BmlMarkerClusterer } from "vue-baidu-map";
import styleJson from "../../../../utils/styleJson";
import deviceTypeInfo from "@/api/manageApi/DeviceTypeManagement";

import companySystem from "@/api/managementApi/CompanyInfo";
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";

export default {
  components: {
    BmlMarkerClusterer,
  },
  data() {
    return {
      baseUrl: BaseData.baseUrl,


      companyNameById: {},
      systemNameById: {},

      dialogVisible: false,
      zoom: 10,
      // 所有设备类型
      deviceType: [],
      deviceTypeChecked: "",
      // 坐标点
      markersOfDevice: [],
      // 坐标对应的设备 id
      deviceId: "",
      typeId: "",

      // 地图风格
      mapStyle: styleJson,

      // 静态属性
      staticAttributes: [],
      // 动态属性
      dynamicProperties: [],
    };
  },
  created() {
    console.log(this.baseUrl);

    this.getAddedDeviceInfoList();
    this.queryMapsEquipment();
  },

  methods: {
    // 所有公司
    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        for (let i in res.data.data.list) {
          this.companyNameById[res.data.data.list[i]["id"]] =
            res.data.data.list[i]["name"];
        }
        console.log(this.companyNameById);
      });
    },

    // 获取权限系统名称
    jurisSysName() {
      noticeSysManagement.getNotifyBigSystemById().then((res) => {
        this.systemNameById = {};
        this.permissionsSystemName = res.data.data.list;
        for (let i in this.permissionsSystemName) {
          this.systemNameById[this.permissionsSystemName[i].id] =
            this.permissionsSystemName[i].bigSystemName;
        }
        console.log(this.systemNameById);
      });
    },

    // 查询设备信息
    getAddedDeviceInfoList() {
      // 设备类型
      deviceTypeInfo.getDeviceTypeList(1, 999, { name: "" }).then((res) => {
        this.deviceType = res.data.data.rows;
        // console.log(this.deviceType);
      });
    },

    // 查询所有设备
    queryMapsEquipment() {
      deviceTypeAboutMap.getAllDeviceTypeAboutMap().then((res) => {
        this.markersOfDevice = res.data.data.list;
        console.log(this.markersOfDevice);
      });
    },

    // 选择设备类型
    checkedValue(item) {
      this.markersOfDevice = "";
      console.log(item);
      if (item.length == 0) {
        this.queryMapsEquipment();
      }
      deviceTypeAboutMap.getDeviceTypeAboutMap(item).then((res) => {
        this.markersOfDevice = res.data.data.list;
      });
    },

    // 点击坐标弹出对应 id
    markerClicked(marker) {
      console.log(marker);
      this.deviceId = marker.deviceId;
      this.typeId = marker.typeId;

      // 静态属性
      deviceInfoManagement.getDeviceInfoById(this.deviceId).then((res) => {
        this.staticAttributes = res.data.data.deviceInfoVo;
      });
      // 动态属性
      deviceInfoManagement
        .getDeviceInfoValueByDeviceId(this.deviceId)
        .then((res) => {
          this.dynamicProperties = res.data.data.list;
        });
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100vw;
  height: 100vh;
  
}
.serachinput {
  width: 240px;
  // margin: 1vh 0 0 26vw;
  margin: 1vh;
}

.container-box {
  font-size: 16px;
  margin: 15px;
  padding: 5px 10px;
  border-bottom: 1px dashed #ccc;
}
.item-box1 {
  box-sizing: border-box;
  display: inline-block;
  width: 35%;
  font-weight: 600;
  text-align: center;
}
.item-box2 {
  box-sizing: border-box;
  display: inline-block;
  width: 65%;
  text-align: center;
}

</style>