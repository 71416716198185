import request from '@/../utils/request'
const api_name = '/iotechserver/companyInfo'

export default {
    // 公司全表结构
    getAllCompanyStructure() {
        return request({
            url: `${api_name}/getAllCompanyStructure`,
            method: 'get'
        })
    },

    // 添加顶级公司
    addHighestCompanyInfo(name) {
        return request({
            url: `${api_name}/addHighestCompanyInfo/${name}`,
            method: 'post'
        })
    },

    // 根据id添加子公司
    addSmallCompanyInfo(id, name) {
        return request({
            url: `${api_name}/addSmallCompanyInfo/${id}/${name}`,
            method: 'post'
        })
    },

    // 删除公司
    deleteCompanyInfo(id) {
        return request({
            url: `${api_name}/deleteCompanyInfo/${id}`,
            method: 'delete'
        })
    },

    // 根据ID修改公司名称
    updateCompanyInfo(id, name) {
        return request({
            url: `${api_name}/updateCompanyInfo/${id}/${name}`,
            method: 'post',
        })
    },

    // 获取所有最高级公司
    getHighestCompanyInfo() {
        return request({
            url: `${api_name}/getHighestCompanyInfo`,
            method: 'get'
        })
    },

    getCompanyInfo() {
        return request({
            url: `${api_name}/getCompanyInfo`,
            method: 'get'
        })
    },



}