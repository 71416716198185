import request from '../../../utils/request'
const api_name = '/iotechserver/deviceInfo'

export default {
    getDeviceInfoList(current, limit, deviceInfoQuery) {
        return request({
            url: `${api_name}/pageDeviceInfo/${current}/${limit}?id=` + deviceInfoQuery.id,
            method: 'get',
        })
    },
    addDeviceInfo(deviceInfo) {
        return request({
            url: `${api_name}/addDeviceInfo`,
            method: 'post',
            data: deviceInfo
        })
    },
    removeDeviceInfo(id) {
        return request({
            url: `${api_name}/removeDeviceInfo/${id}`,
            method: 'delete',
        })
    },
    updataDeviceInfo(deviceInfo) {
        return request({
            url: `${api_name}/updataDeviceInfo`,
            method: 'post',
            data: deviceInfo
        })
    },
    getNoCoordinateDeviceInfoList(deviceInfoQuery) {
        return request({
            url: `${api_name}/getNoCoordinateDeviceInfoList`,
            method: 'get',
            params: deviceInfoQuery
        })
    },
    getDeviceInfoByDeviceType(deviceInfoQuery) {
        return request({
            url: `${api_name}/getDeviceInfoByDeviceType`,
            method: 'get',
            data: deviceInfoQuery
        })
    },
    getDeviceInfoById(id) {
        return request({
            url: `${api_name}/getDeviceInfoById/${id}`,
            method: 'get'
        })
    },
    getAddedDeviceInfoList(deviceInfoQuery) {
        return request({
            url: `${api_name}/getAddedDeviceInfoList`,
            method: 'get',
            params: deviceInfoQuery
        })
    },
    getAllVideo(deviceInfoId) {
        return request({
            url: `${api_name}/getAllVideo/${deviceInfoId}`,
            method: 'get'
        })
    },
    bindVideo(info) {
        return request({
            url: `${api_name}/bindVideo`,
            method: 'post',
            data: info
        })
    },
    getDeviceInfoValueByDeviceId(id) {
        return request({
            url: `${api_name}/getDeviceInfoValueByDeviceId/${id}`,
            method: 'get'
        })
    },
    getAllDeviceTypeAboutMap(){
        return request({
            url: `${api_name}/getAllDeviceTypeAboutMap`,
            method: 'get'
        })
    },
    // 设备实时值
    getDeviceInfo() {
        return request({
            url: `${api_name}/getDeviceInfo`,
            method: 'post'
        })
    },
    // getAllFloorCrt() {
    //     return request({
    //         url: `${api_name}/getAllFloorCrt`,
    //         method: 'post'
    //     })
    // }

}