<template>
  <dv-border-box-13 class="right-chart-1">
    <div class="rc1-header">设备分类</div>

    <div class="rc1-chart-container">
      <!-- <div class="left">
        <div class="number">262</div>
        <div>设备运行总数</div>
      </div> -->

      <dv-capsule-chart class="right" :config="config" />
    </div>
  </dv-border-box-13>
</template>

<script>
export default {
  name: "RightChart1",
  data() {
    return {
      config: {
        data: [
          {
            name: "设备1",
            value: 25,
          },
          {
            name: "设备2",
            value: 66,
          },
          {
            name: "设备3",
            value: 123,
          },
          {
            name: "设备4",
            value: 72,
          },
          {
            name: "设备5",
            value: 99,
          },
          {
            name: "其他设备",
            value: 200,
          },
        ],
        unit: "件",
      },
    };
  },
};
</script>

<style lang="less">
.right-chart-1 {
  width: 25vw !important;
  height: 45vh !important;
  display: flex;
  flex-direction: column;
  .rc1-header {
    padding: 6% 0 2% 4%;
    font-size: 18px;
    color: aqua;
  }
  .rc1-chart-container {
    flex: 1;
    display: flex;
  }
  .number {
    font-size: 34px;
    color: #096dd9;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .right {
    flex: 1;
    padding-bottom: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
.dv-capsule-chart .label-column div {
  height: 3.25vh !important;
  line-height: 3.25vh !important;
  padding-left: 10px;
  font-size: 16px;
}
</style>