<template>
  <div id="app">
    <dv-border-box-13 class="left-top">
      <div class="title">项目总览</div>
      <!-- <dv-decoration-2 style="width:30%;height:5px;margin-left:3%" /> -->
      <dv-decoration-3 style="width: 30%; height: 8px; margin-left: 3%" />
      <div class="contant">
        <div class="item-box" v-for="item in overview" :key="item.id">
          <div>{{ item.title }}</div>
          <div>{{ item.value }}</div>
        </div>
      </div>
    </dv-border-box-13>
  </div>
</template>

<script>
export default {
  name: "LeftTop",
  data() {
    return {
      overview: [
        {
          id: 1,
          title: "项目总数",
          value: "1",
        },
        {
          id: 2,
          title: "项目总数",
          value: "1",
        },
        {
          id: 3,
          title: "项目总数",
          value: "1",
        },
        {
          id: 4,
          title: "项目总数",
          value: "1",
        },
        {
          id: 5,
          title: "项目总数",
          value: "1",
        },
        {
          id: 6,
          title: "项目总数",
          value: "1",
        },
      ],
    };
  },

  created() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.left-top {
  width: 25vw;
  height: 25vh;
  .title {
    padding: 6% 0 2% 4%;
    font-size: 18px;
    color: aqua;
  }
  .contant {
    display: flex;
    flex-wrap: wrap;
    // margin: 10px;

    .item-box {
      width: 26%;
      padding: 3%;
      // height: 30%;
      text-align: center;
      // border:1px solid #008c8c;
      :nth-child(1) {
        margin-top: 20%;
      }
      div {
        margin-top: 10%;
        // padding: 10%;
      }
    }
  }
}
</style>