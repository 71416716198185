var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dv-border-box-13',{staticClass:"left-top"},[_c('div',{staticClass:"bottom-charts"},[_c('div',{staticClass:"bc-chart-item"},[_c('div',{staticClass:"title"},[_vm._v("设备状态")]),_c('dv-active-ring-chart',{attrs:{"config":_vm.deviceStatusL}}),_c('Label-Tag',{attrs:{"config":_vm.labelConfig}})],1),_c('div',{staticClass:"bc-chart-item1"},_vm._l((_vm.deviceStatusR),function(item){return _c('div',[_c('span',{staticClass:"item",class:item.name == '火警设备'
              ? 'huojing'
              : item.name == '故障设备'
              ? 'guzhang'
              : item.name == '失联设备'
              ? 'shilian'
              : item.name == '其他设备'
              ? 'other'
              : 'normal'}),_c('span',[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(item.number)+" 个")])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }