<template>
  <div id="app">
    <dv-border-box-13 class="box">
      <div>
        <div class="title">近7日报警统计</div>
        <dv-decoration-3 style="width: 30%; height: 8px; margin-left: 3%" />
      </div>
      <dv-charts :option="option" class="chart" />
    </dv-border-box-13>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option: {
        series: [
          {
            type: "pie",
            data: [
              { name: "设备1", value: 93 },
              { name: "设备2", value: 32 },
              { name: "设备3", value: 65 },
              { name: "设备4", value: 44 },
              { name: "设备5", value: 52 },
            ],
            legend: {
              textStyle: {
                fontFamily: "Arial",
                fontSize: 18,
                fill: "#000",
              },
            },
            insideLabel: {
              show: true,
            },
            // roseType: true,
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 25vw;
  height: 28vh;
  .title {
    padding: 6% 0 2% 4%;
    font-size: 18px;
    color: aqua;
  }
  .chart {
    width: 100%;
    height: 80%;
  }
}
</style>