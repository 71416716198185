var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dv-border-box-13',{staticClass:"box"},[_c('div',{staticClass:"block"},[_c('el-carousel',{staticClass:"demonstration"},[_c('div',{staticClass:"title"},[_vm._v("最新警情信息")]),_c('dv-decoration-3',{staticStyle:{"width":"30%","height":"8px","margin-left":"3%"}}),_vm._l((_vm.list),function(item){return _c('el-carousel-item',{key:item.id},[_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"line-height":"40px","padding":"0 20px","font-size":"15px"}},[_c('div',[_c('b',[_vm._v("地址： ")]),_vm._v(" "+_vm._s(item.address)+" "),_c('span',{staticClass:"item1",class:item.status.indexOf('火警') != -1
                    ? 'huojing'
                    : item.status.indexOf('故障') != -1
                    ? 'guzhang'
                    : item.status.indexOf('失联') != -1
                    ? 'shilian'
                    : item.status.indexOf('其他') != -1
                    ? 'other'
                    : 'normal'},[_vm._v(_vm._s(item.status))])]),_c('div',[_c('b',[_vm._v("时间： ")]),_vm._v(" "+_vm._s(item.time))]),_c('div',[_c('b',[_vm._v("类型： ")]),_vm._v(" "+_vm._s(item.type))])])])])})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }