<template>
  <dv-border-box-8 id="scroll-board">
    <dv-scroll-board :config="config" />
  </dv-border-box-8>
</template>

<script>
export default {
  name: "Center-bottom",
  data() {
    return {
      config: {
        header: ["时间", "设备名称", "数量", "备注", "其他"],
        data: [
          ["2019-07-01 19:25:00", "路面危害-松散", "5", "xxxxxxx", "123132"],
          [
            "2019-07-02 17:25:00",
            "路面危害-路面油污清理",
            "13",
            "xxxxxxx",
            "123132",
          ],
          [
            "2019-07-03 16:25:00",
            "交安设施-交通标志牌结构",
            "6",
            "xxxxxxx",
            "123132",
          ],
          ["2019-07-04 15:25:00", "路基危害-防尘网", "2", "xxxxxxx", "123132"],
          [
            "2019-07-05 14:25:00",
            "交安设施-交通标志牌结构",
            "1",
            "xxxxxxx",
            "123132",
          ],
          ["2019-07-06 13:25:00", "路面危害-松散", "3", "xxxxxxx", "123132"],
          ["2019-07-07 12:25:00", "路基危害-防尘网", "4", "xxxxxxx", "123132"],
          [
            "2019-07-08 11:25:00",
            "路面危害-路面油污清理",
            "2",
            "xxxxxxx",
            "123132",
          ],
          [
            "2019-07-09 10:25:00",
            "交安设施-交通标志牌结构",
            "5",
            "xxxxxxx",
            "123132",
          ],
          ["2019-07-10 09:25:00", "路基危害-防尘网", "3", "xxxxxxx", "123132"],
        ],
        index: true,
        columnWidth: [50, 170, 300],
        align: ["center"],
        rowNum: 7,
        headerBGC: "#1981f6",
        headerHeight: 45,
        oddRowBGC: "rgba(0, 44, 81, 0.8)",
        evenRowBGC: "rgba(10, 29, 50, 0.8)",
      },
    };
  },
};
</script>

<style lang="less" scoped>
#scroll-board {
  padding: 8px;
  // width: 50vw;
  // height: 43vh;
  height: 80%;
  // border: 1px solid pink;
  // width: 50%;

  box-sizing: border-box;
}
</style>