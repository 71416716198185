<template>
  <div id="app">
    <!-- 标题栏 -->
    <div>
      <router-link to='/Show' class="toggle">
          <i class="el-icon-s-unfold"></i>
      </router-link>
      <h2 class="show-title">智慧消防管理云平台</h2>
      <dv-decoration-5
        style="
          width: 400px;
          height: 30px;
          position: absolute;
          top: 4%;
          left: 40%;
        "
      />
    </div>
    <div class="container">
      <!-- 左 -->
      <!-- <div id="left">
        <div class="left-top">
          <left-top></left-top>
        </div>
        <div class="left-center">
          <left-center></left-center>
        </div>
        <div class="left-bottom">
          <left-bottom></left-bottom>
        </div>
      </div> -->

      <!-- 中 -->
      <div id="center">
        <div class="map">
          <maps></maps>
        </div>
        <!-- <div class="center-bottom">
          <center-bottom></center-bottom>
        </div> -->
      </div>

      <!-- 右 -->
      <!-- <div id="right">
        <div class="right-top">
          <right-top></right-top>
        </div>
        <div class="right-center">
          <right-center></right-center>
        </div>
        <div class="right-bottom">
          <right-bottom></right-bottom>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import LeftTop from "@/components/cloudPlatform/Left/LeftTop";
import LeftCenter from "@/components/cloudPlatform/Left/LeftCenter";
import LeftBottom from "@/components/cloudPlatform/Left/LeftBottom";

import Map from "@/components/cloudPlatform/Center/Map";
import CenterBottom from "@/components/cloudPlatform/Center/Center-bottom";

import RightTop from "@/components/cloudPlatform/Right/RightTop";
import RightCenter from "@/components/cloudPlatform/Right/RightCenter";
import RightBottom from "@/components/cloudPlatform/Right/RightBottom";

export default {
  name: "Show",
  components: {
    LeftTop,
    LeftCenter,
    LeftBottom,
    CenterBottom,
    maps: Map,
    RightTop,
    RightCenter,
    RightBottom,
  },
  data() {
    return {
      
    }
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
#app {
  // width: 100vw;
  height: 100vh;
  // border: 5px solid red;
}
.show-title {
  text-align: center;
  font-size: 30px;
  font-family: "Courier New", Courier, monospace;
  color: rgb(141, 219, 219);
  text-shadow: 2px 2px 2px #000;
  letter-spacing: 5px;
  font-weight: 600;
  padding: 15px 0 20px 30px;
  border-bottom: 1px solid #394755;
  background-color: #272f3b;
}

// 内容
.container {
  padding: 0;
  // border: 1px solid yellow;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #1b2535;
  position: relative;
  #left {
    position: absolute;
    left: 0;
    z-index: 1;
    background: rgba(35, 50, 73, 0.8);
  }
  #center {
    position: relative;
    left: 0;
    bottom: 0;
    .center-bottom {
      position: absolute;
      left: 25vw;
      bottom: -15px;
    }
  }
  #right {
    position: absolute;
    right: 0;
        background: rgba(35, 50, 73, 0.8);
  }
}

// 左边
.left-top {
  width: 25vw;
  height: 25vh;
}
.left-center {
  width: 25vw;
  height: 30vh;
}
.left-bottom {
  width: 25vw;
  height: 45vh;
}

// 中间
.map {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.center-bottom {
  width: 50vw;
  height: 43vh;
}

// 右边
.right-top {
  width: 25vw;
  height: 29vh;
}
.right-center {
  width: 25vw;
  height: 28vh;
}
.right-bottom {
  width: 25vw;
  height: 43vh;
}
.toggle{
  position: absolute;
  top: 20px;
  left: 20px;
  color: #b5edf5;
  box-sizing: border-box;
  font-size: 28px;
}
</style>