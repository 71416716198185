<template>
  <dv-border-box-13 class="box">
    <!-- 轮播图 -->
    <div class="block">
      <el-carousel class="demonstration">
        <div class="title">最新警情信息</div>
        <dv-decoration-3 style="width: 30%; height: 8px; margin-left: 3%" />
        <el-carousel-item v-for="item in list" :key="item.id">
          <div class="content">
            <!-- <h4>副标题</h4> -->
            <div style="line-height: 40px; padding: 0 20px; font-size: 15px">
              <div>
                <b>地址： </b> {{ item.address }}
                <span
                  class="item1"
                  :class="
                    item.status.indexOf('火警') != -1
                      ? 'huojing'
                      : item.status.indexOf('故障') != -1
                      ? 'guzhang'
                      : item.status.indexOf('失联') != -1
                      ? 'shilian'
                      : item.status.indexOf('其他') != -1
                      ? 'other'
                      : 'normal'
                  "
                  >{{ item.status }}</span
                >
              </div>
              <div><b>时间： </b> {{ item.time }}</div>
              <div><b>类型： </b> {{ item.type }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- **** -->
  </dv-border-box-13>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          address: "爱欧科技111",
          status: "故障",
          time: "2022-01-11",
          type: "火警",
        },
        {
          id: 2,
          address: "爱欧科技222",
          status: "失联",
          time: "2022-01-11",
          type: "报警类型",
        },
        {
          id: 3,
          address: "爱欧科技333",
          status: "火警",
          time: "2022-01-11",
          type: "报警类型",
        },
        {
          id: 4,
          address: "爱欧科技444",
          status: "其他",
          time: "2022-01-11",
          type: "报警类型",
        },
        {
          id: 5,
          address: "爱欧科技555",
          status: "故障",
          time: "2022-01-11",
          type: "报警类型",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 25vw;
  height: 28vh;
  .title {
    padding: 6% 0 2% 4%;
    font-size: 18px;
    color: aqua;
  }
}
::-webkit-scrollbar {
  display: none;
}
.block {
  height: 100%;
  background: none;
}

.content {
  padding: 20px;
  color: rgb(193, 243, 245);
  font-size: 16px;
  margin: 13% 0 0 0;
  // height: 60%;
}
.demonstration {
  height: 100% !important;
  background: none;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.item1 {
  margin-left: 20px;
  padding: 3px 6px;
  font-size: 14px;
  border-radius: 5px;
}
.huojing {
  background-color: #f7452d;
  color: #fff;
}
.guzhang {
  background-color: #f7bc3f;
  color: #fff;
}
.shilian {
  background-color: rgb(229, 239, 246);
  color: #333;
}
.other {
  background-color: #94f5fc;
  color: #333;
}
.normal {
  background-color: rgb(80, 74, 74);
}
</style>