<template>
  <div id="app">
    <dv-border-box-13 class="box">
      <div>
        <div class="title">近7日报警统计</div>
        <dv-decoration-3 style="width: 30%; height: 8px; margin-left: 3%" />
      </div>
      <dv-charts :option="option" class="chart" />
    </dv-border-box-13>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option: {
        legend: {
          data: [
            {
              name: "设备A",
              color: "#00baff",
            },
            {
              name: "设备B",
              color: "#ff5ca9",
            },
            {
              name: "设备C",
              color: "#3de7c9",
            },
            {
              name: "设备D",
              color: "#f5d94e",
            },
          ],
          textStyle: {
            fill: "#fff",
          },
        },
        xAxis: {
          data: ["10/01", "10/02", "10/03", "10/04", "10/05", "10/06", "10/07"],
          axisLine: {
            style: {
              stroke: "#999",
            },
          },
          axisLabel: {
            style: {
              fill: "#999",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          data: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            style: {
              stroke: "#999",
            },
          },
          axisLabel: {
            style: {
              fill: "#999",
            },
          },
          axisTick: {
            show: false,
          },
          min: 0,
          max: 8,
        },
        series: [
          {
            name: "设备A",
            data: [2.5, 3.5, 6.5, 6.5, 7.5, 6.5, 2.5],
            type: "bar",
            barStyle: {
              fill: "rgba(0, 186, 255, 0.4)",
            },
          },
          {
            name: "设备B",
            data: [2.5, 3.5, 6.5, 6.5, 7.5, 6.5, 2.5],
            type: "line",
            lineStyle: {
              stroke: "#ff5ca9",
            },
            linePoint: {
              radius: 4,
              style: {
                fill: "#ff5ca9",
                stroke: "transparent",
              },
            },
          },
          {
            name: "设备C",
            data: [1.3, 2.3, 5.3, 5.3, 6.3, 5.3, 1.3],
            type: "line",
            smooth: true,
            lineArea: {
              show: true,
              gradient: ["rgba(55, 162, 218, 0.6)", "rgba(55, 162, 218, 0)"],
            },
            lineStyle: {
              lineDash: [5, 5],
            },
            linePoint: {
              radius: 4,
              style: {
                fill: "#00db95",
              },
            },
          },
          {
            data: [0.2, 1.2, 4.2, 4.2, 5.2, 4.2, 0.2],
            type: "line",
            name: "设备D",
            lineArea: {
              show: true,
              gradient: ["rgba(245, 217, 79, 0.8)", "rgba(245, 217, 79, 0.2)"],
            },
            lineStyle: {
              stroke: "#f5d94e",
            },
            linePoint: {
              radius: 4,
              style: {
                fill: "#f5d94e",
                stroke: "transparent",
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 25vw;
  height: 35vh;
  .title {
    padding: 6% 0 2% 4%;
    font-size: 18px;
    color: aqua;
  }
  .chart {
    width: 100%;
    height: 80%;
  }
}
</style>